import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastrError, ToastrSuccess } from "../../services/toastrService";
import { addWhiteSpace } from "../../services/textFormatterService";
import { getDriverIcon } from "../../components/drivers/DriverIconFactory";
import { RequestType } from "../../components/withAPI";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
  Table,
} from "reactstrap";

import { Form } from "react-bootstrap";

import Loader from "../../components/Loader";
import PromptWithModal from "../../components/PromptWithModal";
import { CustomReportForm } from "./components/CustomReportForm";
import { updateRequiredProperties } from "./components/ConnectionFunctions";
import { useAPI } from "../../components/useAPI";
import TitleWithBackButton from "../../components/TitleWithBackButton";
import {
  ICustomReport,
  ICustomReportInfo,
  ICustomReportParameter,
} from "../../models";
import {
  filterUnusedParameters,
  parseReportNameParameter,
  sortAndShiftParameters,
} from "./components/CustomReportFunctions";
import { CustomReportNameField } from "./components/CustomReportNameField";
import { generateDefaultNumberedName } from "../../utility/generateDefaultNumberedName";
import { compareStrings } from "../../utility/CompareStrings";
import { ButtonType, CDataButton } from "../../components/buttons/CDataButton";
import { CustomReportDeleteReportModal } from "./components/CustomReportDeleteReportModal";
import Page500 from "../auth/Page500";

function AddReport() {
  const api = useAPI();
  const navigate = useNavigate();
  const location = useLocation();

  const driver = (location.state as any)?.driver;
  const connectionId = (location.state as any)?.connectionId;
  const reportType = (location.state as any)?.reportType;
  const overrideConnectionType = (location.state as any)
    ?.overrideConnectionType;
  const isDuplicating: boolean =
    (location.state as any)?.isDuplicating ?? false;
  const createdReportsList: ICustomReport[] =
    (location.state as any)?.createdReportsList ?? [];
  const schema: string | null = (location.state as any)?.schema ?? null;

  const [customReportId, setCustomReportId] = useState(
    (location.state as any).customReportId,
  );
  const [customReportProperties, setCustomReportProperties] = useState<
    ICustomReportParameter[]
  >([]);
  const [requiredProperties, setRequiredProperties] = useState({});
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
  const [processingRequest, setProcessingRequest] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [loading, setLoading] = useState(true);
  const [customReportName, setCustomReportName] = useState("");
  const [mandatoryNameProperties, setMandatoryNameProperties] = useState<
    string[]
  >([]);
  const [errors, setErrors] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    async function initializeData() {
      const defaultCustomReportProperties: ICustomReportParameter[] =
        await getCustomReportProperties();
      if (customReportId) {
        await fillCustomReportProperties(defaultCustomReportProperties);

        if (isDuplicating) {
          setCustomReportId(undefined); // Remove the existing custom report id so that the duplicate is saved as a new report
          setSaveBtnDisabled(false); // Users should be able to save a duplicate report right off the bat without changes
          setUnsavedChanges(true);
        }
      } else {
        setCustomReportProperties(defaultCustomReportProperties);
      }
      setLoading(false);
    }

    initializeData();
  }, []); // eslint-disable-line

  if (!driver || !connectionId || !reportType) {
    return <Page500 />;
  }

  const inputUpdated = () => {
    setUnsavedChanges(true);
  };

  async function getCustomReportProperties(): Promise<
    ICustomReportParameter[]
  > {
    const queryString = new URLSearchParams();
    if (schema) {
      queryString.append("schema", schema);
    }
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      `/utility/drivers/${driver}/customReports/${reportType}?${queryString.toString()}`,
      "Failed to get report parameters due to the following error:",
    );

    if (status === 200) {
      const data: ICustomReportInfo = payload;
      const nameParameters = parseReportNameParameter(data.parameters!);
      const sortedShiftedParameters = sortAndShiftParameters(data.parameters!);

      setMandatoryNameProperties(nameParameters);
      updateRequiredProperties(sortedShiftedParameters, setRequiredProperties);
      return sortedShiftedParameters;
    } else {
      return [];
    }
  }

  async function fillCustomReportProperties(
    defaultCustomReportProperties: ICustomReportParameter[],
  ) {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      `/account/connections/${connectionId}/customReports/${customReportId}`,
      "Failed to get connection properties due to the following error:",
    );

    if (status === 200) {
      const paramValues = payload.parameters;
      const filledCustomReportProperties = mapPropertiesToExistingValues(
        defaultCustomReportProperties,
        paramValues,
      );
      setCustomReportProperties(filledCustomReportProperties);

      const reportName: string = payload.name;
      if (isDuplicating) {
        setDuplicateReportName(reportName);
      } else {
        setCustomReportName(reportName);
      }
    } else {
      if (status !== 401) {
        const err = payload;
        ToastrError(
          "Failed to get connection properties due to the following error:",
          err.error,
        );
      }
    }
  }

  function mapPropertiesToExistingValues(
    defaultCustomReportProperties: ICustomReportParameter[],
    paramValues: any,
  ) {
    const filledCustomReportProperties: any = [
      ...defaultCustomReportProperties,
    ];

    Object.keys(filledCustomReportProperties).forEach((propertyName) => {
      const propName =
        filledCustomReportProperties[propertyName].name.toLowerCase();

      if (paramValues[propName]) {
        filledCustomReportProperties[propertyName].default =
          paramValues[propName];
      }
    });

    return filledCustomReportProperties;
  }

  function navigateToCustomReportTab() {
    // Make it so that the back button sends the user to the connection list instead of back here
    window.history.replaceState({}, "", "/connections");
    navigate("/connections/edit", {
      state: {
        driverType: driver,
        connectionId: connectionId,
        hasCustomReports: true,
        overrideConnectionType: overrideConnectionType,
        currentTab: "4",
      },
    });
  }

  function setDuplicateReportName(reportName: string) {
    // The number incrementer for the name requires a base report name; this removes any trailing digits to form this base.
    const reportNameWithoutTrailingNumbers = reportName.replace(/\d+$/, "");
    setCustomReportName(
      generateDefaultNumberedName(
        reportNameWithoutTrailingNumbers,
        createdReportsList,
      ),
    );
  }

  async function handleValidSubmit(event: any) {
    event.preventDefault();

    // Get values from form
    const formData = new FormData(event.target);
    const name = Object.fromEntries(formData.entries()).name?.toString();
    for (const nameProperty of mandatoryNameProperties) {
      formData.append(nameProperty, name);
    }
    const values = Object.fromEntries(formData.entries());
    delete values["name"];

    //updating error state for fields if any error exist return submit function
    let errorFound = false;
    const errorMessages: any = {};
    for (const key in values) {
      if (
        compareStrings(values[key] as string, "Select") &&
        requiredProperties[key as keyof typeof requiredProperties]
      ) {
        errorFound = true;
        errorMessages[key] = `${key} is a required field`;
      } else {
        errorMessages[key] = "";
      }
    }
    setErrors(errorMessages);

    if (errorFound) {
      return;
    }

    //updating select values as "".
    for (const key in values) {
      if (compareStrings(values[key] as string, "Select")) delete values[key];
    }

    setSaveBtnDisabled(true);
    setProcessingRequest(true);

    // Set up request body
    const newDataSet: {
      Name: string;
      Parameters: any;
      ReportType: string;
    } = {
      Name: name,
      Parameters: values,
      ReportType: reportType,
    };

    const { status, payload } = await api.callAPI(
      RequestType.Post,
      `/AddEditCustomReport/${connectionId}/driver/${driver}/${customReportId}`,
      "Failed to generate custom report due to the following error:",
      newDataSet,
    );

    if (status === 200 || status === 204) {
      // If we're adding a new report, the payload contains the report object with its id, which we set to the state variable.
      if (!customReportId) {
        const newReportId = payload.customReport.id;
        setCustomReportId(newReportId);
        (location.state as any).customReportId = newReportId;
      }

      if (payload.testCustomReportError) {
        ToastrError(
          "Unable to successfully test your custom report",
          payload.testCustomReportError.error.message,
        );
      } else {
        ToastrSuccess(
          "Custom Report successfully saved",
          `${name} was successfully saved.`,
        );
      }

      setUnsavedChanges(false);
    }
    setSaveBtnDisabled(false);
    setProcessingRequest(false);
  }

  if (loading) {
    return <Loader />;
  }

  const advancedSettingsBody = (
    <>
      <Card data-testid="card-info" className="mt-3">
        <CardBody>
          <Col>
            <h5 className="card-title mb-2">General</h5>
          </Col>
          <Table>
            <thead>
              <tr>
                <th className="no-border ps-0">Data Source</th>
                <th className="no-border ps-0">Report Type</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-testid="card-info-driver" className="border-0 p-0">
                  {getDriverIcon(driver, "connection-icon me-2 p-0")}
                  {driver}
                </td>
                <td data-testid="card-info-type" className="border-0 p-0">
                  {addWhiteSpace(reportType)}
                </td>
              </tr>
            </tbody>
          </Table>
          <CustomReportNameField
            handleInputUpdate={inputUpdated}
            name={customReportName}
          />
        </CardBody>
      </Card>
      {filterUnusedParameters(customReportProperties)?.length > 0 ? (
        <Card>
          <CardBody>
            <CustomReportForm
              customReportName={customReportName}
              inputUpdated={inputUpdated}
              customReportProperties={customReportProperties}
              errors={errors}
            />
          </CardBody>
        </Card>
      ) : null}
    </>
  );

  return (
    <>
      <Container
        fluid
        data-testid="add-reports"
        className="pages-connections-AddReports p-0"
      >
        <div hidden={!processingRequest}>
          <div className="loading-background" />
          <Spinner className="spinner-border loading-spinner" color="info" />
        </div>
        <PromptWithModal when={unsavedChanges} navigate={() => navigate(-1)} />
        <Form
          id="connectionProps"
          data-testid="form-add-reports"
          onSubmit={handleValidSubmit}
        >
          <Row className="mb-3">
            <Col>
              <TitleWithBackButton
                title={`${customReportId ? "Edit" : "Add"} Custom Report`}
                noMargin={true}
                customNavigate={() => navigateToCustomReportTab()}
              />
            </Col>
            <Col className="d-flex justify-content-end gap-1">
              {customReportId && (
                <CDataButton
                  buttonType={ButtonType.Secondary}
                  data-testid="button-delete"
                  onClick={() => setShowDeleteModal(true)}
                >
                  <i className="fa-regular fa-xmark me-2" />
                  Delete
                </CDataButton>
              )}
              <CDataButton
                buttonType={ButtonType.Primary}
                data-testid="button-save"
                type="submit"
                disabled={saveBtnDisabled || !unsavedChanges}
              >
                <i className="fa fa-save fa-solid fa-sm me-2 no-pointer-event" />
                Save Changes
              </CDataButton>
            </Col>
          </Row>
          <Row>
            <Col>{advancedSettingsBody}</Col>
          </Row>
        </Form>
      </Container>
      <CustomReportDeleteReportModal
        key="DeleteReportModal"
        connectionId={connectionId}
        selectedReports={[{ id: customReportId }]}
        isModalOpen={showDeleteModal}
        onCloseModal={() => setShowDeleteModal(false)}
        onDeleteSucceeded={() => {
          setShowDeleteModal(false);
          ToastrSuccess(
            "Custom Report deleted successfully",
            `Your custom report was successfully deleted.`,
          );
          navigateToCustomReportTab();
        }}
      />
    </>
  );
}

export default AddReport;
